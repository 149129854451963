<template>
<div class = "col-right">
    <a-spin :spinning="spinning">
        <a-card
                style = "width:100%"
                solt = "title"
        >
            <h3 style = "margin-bottom: 26px">
                售后订单:{{orderInfo.refund_no}}&nbsp;&nbsp;&nbsp;<a-button type = "primary">{{orderInfo.refund_state_desc}}</a-button>
            </h3>
            <a-card-meta>
                <template slot = "description">
                    <a-row type = "flex">
                        <a-col :span = "8">
                            <div class = "label">所属订单号：{{orderInfo.order_no}} </div>
                        </a-col>
<!--                        <a-col :span = "8">-->
<!--                            <div class = "label">售后单类型：退款</div>-->
<!--                        </a-col>-->
                        <a-col :span = "8">
                            <div class = "label">下单方式：开放平台</div>
                        </a-col>
                        <a-col :span = "8">
                            <div class = "label">下单渠道：????</div>
                        </a-col>
                    </a-row>

                    <div class = "box-step">
                        <a-steps :current = "steps.length" status = "success" labelPlacement = "vertical">
                            <a-step v-for="(step, idx) in steps" :title = "step.title" :description = "step.description" :key="idx" />
                        </a-steps>
                    </div>
                    <a-table :columns = "columns" :data-source = "orderInfo.orderList" bordered :pagination="false">
                        <div slot = "goods_title" slot-scope="text, record">
                            <div class = "order-goods ng-star-inserted">
                                <div class = "order-goods-img">
                                    <img :src = "record.goods_logo">
                                </div>
                                <div class = "order-goods-info">
                                    <h3>
                                        <span class = "overflowText">{{record.goods_title}}<br><span class = "ng-star-inserted">{{record.goods_spec}} </span></span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div slot = "price_goods">{{orderInfo.price_goods}}</div>
                        <div slot = "price_express">{{orderInfo.price_express}}</div>
                        <div slot = "refund_price">{{orderInfo.refund_price}}</div>
                    </a-table>

                </template>
            </a-card-meta>
        </a-card>
        <a-card>
            <a-row>
                <a-col :span="3">售后理由：</a-col>
                <a-col :span="21">{{orderInfo.reason_desc}}</a-col>
            </a-row>
            <a-row>
                <a-col :span="3">上传图片：</a-col>
                <a-col :span="21">
                    <img style="max-width: 100%;" v-if="orderInfo.refund_image" :src="orderInfo.refund_image">
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="3">问题描述：</a-col>
                <a-col :span="21">{{orderInfo.refund_desc}}</a-col>
            </a-row>
        </a-card>
    </a-spin>

</div>
</template>

<script>
import service from "../../../utils/request";

export default {
    name: "refund-detail",
    data() {
        return {
            spinning: false,
            columns: [
                {
                    title: "商品",
                    dataIndex: "goods_title",
                    scopedSlots: {customRender: 'goods_title'},
                    width: '300px'
                },
                {
                    title: "售后数量",
                    dataIndex: "number_goods",
                    scopedSlots: {customRender: 'number_goods'},
                },
                {
                    title: "可退金额",
                    dataIndex: "price_goods",
                    scopedSlots: {customRender: 'price_goods'},
                },
                {
                    title: "运费金额",
                    dataIndex: "price_express",
                    scopedSlots: {customRender: 'price_express'},
                },
                {
                    title: "小计",
                    dataIndex: "refund_price",
                    scopedSlots: {customRender: 'refund_price'},
                },
            ],
            goodsList: [],
            orderInfo:{},
            steps:[]
        }
    },
    created() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            this.spinning = true;
            service.post(service.uri.order.refundDetail, {id: this.$route.params.id}).then(res => {
                this.spinning = false;
                if(res.code == 200){
                    this.orderInfo = res.data;
                    this.steps.push({title:'提交', description: this.orderInfo.refund_at});
                    this.steps.push({title:'售后中', description:''});
                    if([2,4,6].includes(this.orderInfo.refund_state)){
                        this.steps.push({title:'售后完成', description:''});
                    }

                }
            });
        }
    }
}
</script>

<style lang = "scss" scoped>
@import "../../assets/css/common";

.ant-btn-primary {
    background-color: $defaultColor;
}

div.label {
    padding-bottom: 16px;
}

.box-step {
    margin: 30px 0;
    padding: 20px 50px;
}
.order-goods {
    display: flex;
    text-align: left;

    .order-goods-img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    .order-goods-info {
        display: flex;
        flex-direction: column;

        .center-space {
            margin: 0;
            flex: 1;
            width: 100%;
        }
    }
}
</style>